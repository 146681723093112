import './features.css';
import keynote from '../../assets/1-keynote.png';
import panel from '../../assets/2-panel.png';
import charting from '../../assets/3-charting.png';
import emotions from '../../assets/4-emotions.png';

const Features = () => {
    return ( 
        <section class="features" id="features">
            <div class="container">
                <h2>Features</h2>
                <div class="feature-group">

                    <div className='feature-item'>
                        <img src={keynote} alt='keynote' className='img-fluid' />
                        <div>Keynote Presentations</div>
                    </div>
                    <div className='feature-item'>
                        <img src={panel} alt='panel' className='img-fluid' />
                        <div>Panel Sessions</div>
                    </div>
                    <div className='feature-item'>
                        <img src={charting} alt='charting' className='img-fluid' />
                        <div>Awards & Recognition of members, partners, and industry leaders</div>
                    </div>
                    <div className='feature-item'>
                        <img src={emotions} alt='emotion' className='img-fluid' />
                        <div>Exhibitions</div>
                    </div>

                </div>
                
            </div>
        </section>
    );
}
 
export default Features;