import './header.css';

const Header = () => {
    return (
        <header>
            <div className="container header">
            <div className="header-group">
                <div className="header-title"> Business Women Hub Conference 2024 </div>
                <div className="theme"> Female Inclusion: A Step To Forging A Better World</div>
                <div className="date"> Date: Tuesday 26th March, 2024, 9:00AM</div>
                <div className='venue'>Venue: Lily Gate Hotel Lekki Phase 1, Lagos</div>
                <a href="https://bit.ly/BWHConference2024" target="_blank" rel="noreferrer" className='header-register-button'>Register Now</a>
            </div>
            </div>
        </header>
    );
}
 
export default Header;