import './navbar.css';
import bwhlogo from '../../assets/bwh-logo.png';

const Navbar = () => {
    return ( 
        <nav>
        <div className="container navbar">
            <img src={ bwhlogo } alt='' className="main-logo img-fluid"  />
            <div class="link-group">
                    <a href="#about" className='link-item'>About</a>
                    <a href="#features" className='link-item'>Features</a>
                    <a href="#topics" className='link-item'>Topics</a>
                    <a href="#speakers" className='link-item'>Speakers</a>
                    <a href="#agenda" className='link-item'>Agenda</a>
            </div>
                <a href="https://bit.ly/BWHConference2024" target="_blank" rel="noreferrer" id="register">Register Now</a>
            </div>
        </nav>
     );
}
 
export default Navbar;