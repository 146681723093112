import './speakers.css';

const SpeakerCard = (props) => {
    
    return ( 
        <div className='speaker-item' key={props.id}>
            <img src = { `./images/${props.imageUrl}` } alt=' ' className='img-fluid' />
            <div className='speaker_info'>
                <div className='speaker_title'>{props.title}</div>
                <div className='speaker_position'>{props.position}</div>
                <div className='speaker_company'>{props.company}</div>
            </div>
        </div>
    );
}
 
export default SpeakerCard;