import './speakers.css';
import speakerData from '../../data/index.js';
import SpeakerCard from './SpeakerCard';

const SpeakerList = () => {

    const SpeakerCards = speakerData.map((speaker)=> {
        return <SpeakerCard
        key = {speaker.id}
        id = {speaker.id}
        title = {speaker.title}
        position = {speaker.position}
        company = {speaker.company}
        imageUrl = {speaker.imageUrl}
        />
    }) 

    return ( 
        <section class=" container-fluid speakers" id="speakers">
            <div class="container">
                <h2>Speakers</h2>
                <div class="speaker_group">
                    { SpeakerCards }
                </div>
            </div>
        </section>
    );
}
 
export default SpeakerList;