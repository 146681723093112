import Features from '../features/Features';
import About from '../about/About';
import Header from '../header/Header';
import Agenda from '../agenda/Agenda';
import './home.css';
import SpeakerList from '../speakers/SpeakerList';
import Brochure from '../brochure/Brochure';
// import Topics from '../topics/Topics';

const Home = () => {
    return (
        <main>
        <Header />
        <Brochure />
        <About />
        <Features />
        <SpeakerList />
        <Agenda />
        {/* <Topics /> */}
        </main>
    );
}
 
export default Home;