const speakerData = [
    {
        id : "1",
        title : "Imelda Usoro-Olaoye (Convener)",
        position : "Founder, Business Women hub",
        company : "Managing Partner, Thinkmint Nigeria",
        imageUrl : "imeldaolaoye.png"
    },
    {
        id : "1v",
        title : "Daniel Etim Effiong (Guest Appearance)",
        position : "Actor, Filmmaker & Event Host",
        company : "",
        imageUrl : "daniel.png"
    },
    {
        id : "2",
        title : "Yeside Aminat Olasimbo (keynote Speaker)",
        position : "Special Assistant on Women Affairs to the Speaker House of Representatives",
        company : "Federal Republic of Nigeria",
        imageUrl : "yesideaminat.png"
    },
    {
        id : "3",
        title : "Mrs Ini Abimbola (keynote Speaker)",
        position : "Vice President",
        company : "Sustainability Professionals Institute of Nigeria",
        imageUrl : "iniabimbola.png"
    },
    {
        id : "4",
        title : "Abiola Adelana",
        position : "Head Tourism and creative Art Business",
        company : "Sterling Bank plc",
        imageUrl : "abiolaadelana.png"
    },
    {
        id : "5",
        title : "Gusi Tobby Lordwilliams",
        position : "Social Impact Strategist,",
        company : "Communitcations Expert",
        imageUrl : "gusitobby.png"
    },
    {
        id : "6",
        title : "Olubunmi Ebhomenye",
        position : "Director",
        company : "Talent Academy, Flour Mills Nigeria",
        imageUrl : "olubunmi.png"
    },
    {
        id : "7",
        title : "Ubong Ita",
        position : "CEO & Co-Founder",
        company : "Konoaccountant / etiaba",
        imageUrl : "ubongita.png"
    },
    {
        id : "8",
        title : "Ella Ezeadilieje PhD",
        position : "Story Teller, Brand Strategist Influencer",
        company : "",
        imageUrl : "eze.png"
    },
    {
        id : "9",
        title : "Ayo Bankole",
        position : "Strategy & Transformation Professional",
        company : "Caladium Consulting",
        imageUrl : "ayobankole.png"
    },
    {
        id : "10",
        title : "Sarah Samuel Amana",
        position : "Personal Finance Expert",
        company : "",
        imageUrl : "sarah.png"
    },
    {
        id : "11",
        title : "Hussaina Yakubu",
        position : "Motivational Speaker, Founder",
        company : "Hussy Foods",
        imageUrl : "hussaina.png"
    },
    {
        id : "12",
        title : "Mosunmola Nicole E.",
        position : "Chief Experience Officer",
        company : "IPC-Event Nigeria",
        imageUrl : "mosunmola.png"
    },
    {
        id : "13",
        title : "Linda Ogbugbua",
        position : "Head, Digital Advert Business Day/SME Business Expert",
        company : "IPC-Event Nigeria",
        imageUrl : "linda.png"
    },
    {
        id : "14",
        title : "Emem Attah",
        position : "MC",
        company : "",
        imageUrl : "emem.png"
    },

]


export default speakerData;