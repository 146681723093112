import './footer.css';

const Footer = () => {
    return ( 
        <footer>
            <div className='footer-title'>To Exhibit or Participate </div>
            <div className='footer-item'>234 812 8661 645 | 234 906 0441 372 | 234 809 7845 064</div>
            <div className='footer-item'>hello@businesswomen.com.ng</div>
        </footer>
    );
}
 
export default Footer;