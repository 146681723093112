import './about.css';

const About = () => {
    return ( 
        <section className="about-container container" id="about">
            <div className="about-text">
                <h2>The 2024 Business Wowen Hub Conference</h2>
                <p>In this era of technology, Building and growing your business empire
                    with the right tools and drive is crucial. The 2024 edition of the Business Women Hub Conference themed: Female Inclusion: A Step To Forging A Better World is organized by the Businesswomen Hub, a platform dedicated to the growth of female-owned businesses via sales and marketing training, general business workshops, and financial sourcing for these businesses in Africa, especially within their first five years of existence. In line with the goals of this year's International Women's Day,
                </p>
                <p>
                    we will be discussing the need to expand processes through innovation and technology in the business sector, how to become relevant in their niche, as well as ways to provide top-notch services in their businesses.
                </p>
                <p>
                Why are we doing this? Globally, women are gradually taking their place in business in Africa via technology and new media, particularly in Nigeria. Though, not at a fast pace. We hope to speed this up by encouraging women to break the stereotypical African woman's status and venture into different business sectors.
                </p>
                <a href="https://bit.ly/BWHConference2024" target="_blank" rel="noreferrer" className='about-register-button'>Register Now</a>
            </div>
        </section>
     );
}
 
export default About;